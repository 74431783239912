import React from 'react'
import { Head } from '~/components'

import * as st from '~/assets/styl/404.module.styl'

import bauerLogo from '~/assets/img/bauer-turismo-red.png'

import { Link } from 'gatsby'

const NotFound = () => (
  <>
    <Head title={'Página não encontrada - ' + process.env.GATSBY_SITE_NAME} />
    <div className={st.notFoundWrapper}>
      <img src={bauerLogo} alt="Logo da Bauer Turismo" />
      <section className={st.notFound}>
        <h2>
          Ops! <br />
          Página não encontrada
        </h2>
        <p>
          A página que você tentou acessar não existe ou está fora do ar
          temporariamente. Verifique o endereço do link e tente outra vez.
        </p>
        <div>
          <Link to="/" className={st.back}>
            Ir para a home
          </Link>
          <Link to="/pacotes-turisticos" className={st.back}>
            Conheça nossos pacotes
          </Link>
          <Link to="/servicos" className={st.back}>
            Conheça nossos serviços
          </Link>
        </div>
      </section>
    </div>
  </>
)

export default NotFound
